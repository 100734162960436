import React, { useEffect, useState } from "react";

import Head from "../components/Head/Head";
import Layout from "../components/Layout/Layout";

import "../styles/global.scss";
import "../styles/typography.scss";

import HomepageStyles from "./index.module.scss";

const Home = () => {
  const [slideLetters, setSlideLetters] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setSlideLetters(true);
  }, []);

  const handleTransitionEnd = (e) => {
    if (e.propertyName === "left") {
      setCounter(counter + 1);
    }
    if (counter === 2) {
      setShowNav(true);
    }
  };

  return (
    <Layout showNav={showNav}>
      <Head />
      <section className={HomepageStyles.landingPage}>
        <h1
          className={slideLetters ? HomepageStyles.fadeIn : ""}
          onTransitionEnd={(e) => handleTransitionEnd(e)}
        >
          E
          <span
            className={`${HomepageStyles.m1} ${
              slideLetters ? HomepageStyles.slideOver : ""
            }`}
          >
            m
          </span>
          <span
            className={`${HomepageStyles.m2} ${
              slideLetters ? HomepageStyles.slideOver : ""
            }`}
          >
            m
          </span>
          a Ja
          <span
            className={`${HomepageStyles.m3} ${
              slideLetters ? HomepageStyles.slideOver : ""
            }`}
          >
            m
          </span>
          es Tattoo
        </h1>
      </section>
    </Layout>
  );
};

export default Home;
